// import { throttle, debounce } from 'throttle-debounce';

import AOS from 'aos';
import lightbox from 'lightbox2';


$(window).on('load', function() {
  $('body').addClass('is-loaded');

  // AOS
  if($('[data-aos]').length > 0) {
    AOS.init({
      duration: 800
    });
  }

  // LIGHTBOX
  if($('[data-lightbox]').length > 0) {
    lightbox.option({
      "alwaysShowNavOnTouchDevices": true,
      "disableScrolling": false,
      "fadeDuration": 200,
      "fitImagesInViewport": true,
      "imageFadeDuration": 200,
      "positionFromTop": 50,
      "resizeDuration": 200,
      "showImageNumberLabel": false,
      "wrapAround": true
    });
  }

});

import "./front/_bathtub"
import "./front/_browser"
import "./front/_contact-form"
import "./front/_display"
import "./front/_header"
import "./front/_navigation"
import "./front/_newsletter"
import "./front/_slider"
import "./front/_summary"
import "./front/_tabs"
import "./front/_toggle"
