/**
** JAVASCRIPT
** Name: Header
********************************************************************************/

(function() {
  'use strict';


  if($('.js-header').length > 0) {


    /**
    ** Variables
    ****************************************/

    var $win          = $(window),
        $doc          = $(document),
        $body         = $('body'),
        $header       = $('.js-header'),
        $message      = $('.js-header-message'),
        $head         = $('.js-header-head'),
        $menu         = $('.js-header-menu'),
        $navItems     = $('.js-navigation-item'),
        scrollTop     = $win.scrollTop(),
        lastScrollTop = 0,
        hasMessage    = $message.length > 0,
        delta         = 1;


    /**
    ** Init
    ****************************************/

    $win.on('load', function() {

      if($head.css('display') == 'none') {
        delta = 1;

        if(hasMessage) {
          delta = $message.outerHeight() + 1;
        }
      }
      else {
        delta = $head.outerHeight() + 1;

        if(hasMessage) {
          delta = $head.outerHeight() + $message.outerHeight() + 1;
        }
      }

      if($win.scrollTop() >= delta) {
        $header.addClass('is-fixed');
      }
      else {
        $header.removeClass('is-fixed');
      }
    });


    /**
    ** Resize
    ****************************************/

    $win.on('resize', function() {

      if($head.css('display') == 'none') {
        delta = 1;

        if(hasMessage) {
          delta = $message.outerHeight() + 1;
        }
      }
      else {
        delta = $head.outerHeight() + 1;

        if(hasMessage) {
          delta = $head.outerHeight() + $message.outerHeight() + 1;
        }
      }
    });


    /**
    ** Scroll
    ****************************************/

    $win.on('scroll', function() {

      if($win.scrollTop() >= delta) {
        $header.addClass('is-fixed');
      }
      else {
        $header.removeClass('is-fixed');
      }
    });


    /**
    ** Functions
    ****************************************/

    $menu.on('click', function(event) {
      event.preventDefault();

      $navItems.removeClass('is-active');
      $body.removeClass('navigation-panel');
      $body.toggleClass('menu-panel');
    });

  }

})();
