/**
** JAVASCRIPT
** Name: Contact form
********************************************************************************/

(function() {
  'use strict';


  if($('.js-contact-form').length > 0) {


    /**
    ** Variables
    ****************************************/

    var $win     = $(window),
        $doc     = $(document),
        $body    = $('body'),
        $form    = $('.js-contact-form'),
        $field   = $form.find('.c-form__field[data-field="service"]'),
        $sav     = $field.find('.c-radio__input[value="sav"]'),
        $radios  = $('.c-radio__input[name="' + $sav.attr('name') + '"]'),
        $serial  = $('.c-form__field[data-field="serial_number"]');


    /**
    ** Function
    ****************************************/

    function setSerial() {
      
      if($sav.is(':checked')) {
        $serial.show();
      }
      else {
        $serial.hide();
      }
    };


    /**
    ** Init
    ****************************************/

    $doc.ready(function() {
      setSerial();
    });


    /**
    ** On change
    ****************************************/

    $radios.on('change', function() {
      setSerial();
    });

  }

})();
