/**
** JAVASCRIPT
** Name: Tabs
********************************************************************************/

(function() {
  'use strict';


  if($('.js-tabs').length > 0) {


    /**
    ** Variables
    ****************************************/

    var $win   = $(window),
        $doc   = $(document),
        $body  = $('body'),
        $tabs  = $('.js-tabs'),
        $items = $('.js-tabs-items'),
        $item  = $('.js-tabs-item'),
        $head  = $('.js-tabs-head'),
        $body  = $('.js-tabs-body');

    $item.addClass('is-hidden');


    /**
    ** Click
    ****************************************/

    $head.on('click', function(event) {
      event.preventDefault();

      var $this = $(this),
          $item = $this.parents('.js-tabs-item');

      $item.toggleClass('is-hidden');
    });

  }

})();
