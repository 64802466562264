/**
** JAVASCRIPT
** Name: Summary
********************************************************************************/

(function() {
  'use strict';


  if($('.js-summary').length > 0) {


    /**
    ** Variables
    ****************************************/

    var $win            = $(window),
        $doc            = $(document),
        $body           = $('body'),
        $header         = $('.js-header-body'),
        $summary        = $('.js-summary'),
        $items          = $('.js-summary-items'),
        $links          = $('.js-summary-link'),
        links           = [],
        delta           = $summary.offset().top - $header.outerHeight(),
        offset          = 200,
        preventTracking = false;


    /**
    ** Functions
    ****************************************/

    function setActiveLink() {
      var activeLink = false;

      $.each(links, function(index, link) {
        if($win.scrollTop() >= (link.start - offset) && $win.scrollTop() < (link.end - offset)) {
          var $currentLink = $('.js-summary-link[href="' + link.id + '"]'),
              $currentItem = $currentLink.parents('.js-summary-item');

          activeLink = true;

          $links.not($currentLink).removeClass('is-active');
          $currentLink.addClass('is-active');
          $items.scrollLeft($currentItem.offset().left + $items.scrollLeft() - ($currentItem.outerWidth() / 2));
        }
      });

      if(activeLink == false) {
        $links.removeClass('is-active');
      }
    }


    /**
    ** Init
    ****************************************/

    $win.on('load', function() {
      delta = $summary.offset().top - $header.outerHeight();

      $links.each(function() {
        var $this   = $(this),
            id      = $this.attr('href'),
            $target = $(id),
            start   = $target.offset().top - 50,
            end     = start + $target.outerHeight();

        links.push({
          id: id,
          start: start,
          end: end
        });
      });

      if($win.scrollTop() >= delta) {
        $summary.addClass('is-fixed');
      }
      else {
        $summary.removeClass('is-fixed');
      }

      setActiveLink();
    });


    /**
    ** Resize
    ****************************************/

    $win.on('resize', function() {
      $summary.removeClass('is-fixed');

      links = [];
      delta = $summary.offset().top - $header.outerHeight();

      $links.each(function() {
        var $this   = $(this),
            id      = $this.attr('href'),
            $target = $(id),
            start   = $target.offset().top - 50,
            end     = start + $target.outerHeight();

        links.push({
          id: id,
          start: start,
          end: end
        });
      });

      if($win.scrollTop() >= delta) {
        $summary.addClass('is-fixed');
      }
      else {
        $summary.removeClass('is-fixed');
      }

      setActiveLink();
    });


    /**
    ** Scroll
    ****************************************/

    $win.on('scroll', function() {

      if($win.scrollTop() >= delta) {
        $summary.addClass('is-fixed');
      }
      else {
        $summary.removeClass('is-fixed');
      }

      if (!preventTracking) {
        setActiveLink();
      }
    });


    /**
    ** Click
    ****************************************/

    $links.on('click', function(event) {
      event.preventDefault();

      var $target  = $($(this).attr('href')),
          padding  = $target.data('offset') ? $target.data('offset') : 100,
          position = $target.offset().top - padding;

      preventTracking = true;

      // Prevent conflict behaviors between CSS and JS behaviors
      $('html, body').css('scroll-behavior', 'auto');

      $('html, body').stop().animate({
        scrollTop: position
      }, 300, 'swing', function() {
        $('html, body').css('scroll-behavior', '');
        preventTracking = false;
        setActiveLink();
      });
    });

  }

})();

