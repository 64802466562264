(function() {
  'use strict';


  if($('.js-newsletter').length > 0) {

    document.addEventListener("DOMContentLoaded", function() {

      var $newsletters = document.querySelectorAll('.js-newsletter');

      $newsletters.forEach(function($newsletter) {

        // Variables
        var $form      = $newsletter.querySelector('.js-newsletter-form'),
            $feedback  = $newsletter.querySelector('.js-newsletter-feedback'),
            _isLoading = false;

        // Submit
        $form.addEventListener('submit', function(e) {

          if (_isLoading) {
            return;
          }

          _isLoading = true;

          e.preventDefault();

          $.ajax({
            type: "POST",
            url: $form.action,
            data: $($form).serialize(),
            success: function(response) {
              $form.classList.add('u-hidden');
              $feedback.classList.remove('u-hidden');
            },
            error: function() {
              // Nothing..
            },
            complete: function() {
              _isLoading = false;
            }
          });

        });
      });

    });

  }

})();
