/**
** JAVASCRIPT
** Name: Slider
********************************************************************************/

import $ from 'jquery';
import 'slick-carousel';

(function() {
  'use strict';


  if($('.js-slider').length > 0) {


    /**
    ** News
    ****************************************/

    $('.js-slider[data-id="news"] .js-slider-items').slick({
      autoplay: true,
      autoplaySpeed: 4000,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      dotsClass: "v-news__dots",
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 959,
          settings: {
            slidesToShow: 2,
            dots: false
          }
        }
      ]
    });

    $('.js-slider[data-id="news"] .js-slider-prev').on('click', function() {
      $(this).parents('.js-slider').find('.js-slider-items').slick('slickPrev');
    });

    $('.js-slider[data-id="news"] .js-slider-next').on('click', function() {
      $(this).parents('.js-slider').find('.js-slider-items').slick('slickNext');
    });


    /**
    ** Products
    ****************************************/

    $('.js-slider[data-id="products"] .js-slider-items').slick({
      autoplay: true,
      autoplaySpeed: 4000,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      dotsClass: "v-product__dots",
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 959,
          settings: {
            slidesToShow: 2,
            dots: false
          }
        }
      ]
    });

    $('.js-slider[data-id="products"] .js-slider-prev').on('click', function() {
      $(this).parents('.js-slider').find('.js-slider-items').slick('slickPrev');
    });

    $('.js-slider[data-id="products"] .js-slider-next').on('click', function() {
      $(this).parents('.js-slider').find('.js-slider-items').slick('slickNext');
    });


    /**
    ** Systems
    ****************************************/

    $('.js-slider[data-id="systems"] .js-slider-items').slick({
      autoplay: true,
      autoplaySpeed: 4000,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      dotsClass: "c-systems__dots",
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 959,
          settings: {
            slidesToShow: 2,
            dots: false
          }
        }
      ]
    });

    $('.js-slider[data-id="systems"] .js-slider-prev').on('click', function() {
      $(this).parents('.js-slider').find('.js-slider-items').slick('slickPrev');
    });

    $('.js-slider[data-id="systems"] .js-slider-next').on('click', function() {
      $(this).parents('.js-slider').find('.js-slider-items').slick('slickNext');
    });

    if($('.js-slider[data-id="systems"]').length > 0) {
      $('.js-slider[data-id="systems"]').each(function() {
        var $this  = $(this),
            $slide = $this.find('.slick-slide'),
            $ctrls = $('.js-slider-controls');
            length = $slide.length;

        if(length < 3) {
          $ctrls.remove();

          $this.find('.c-systems__item').first().addClass('no-separator');
        }
      });
    }


    /**
    ** Product
    ****************************************/

    $('.js-slider[data-id="product"] .js-slider-items').slick({
      autoplay: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      dotsClass: "c-slider__dots",
      mobileFirst: true
    });

    $('.js-slider[data-id="product"] .js-slider-prev').on('click', function() {
      $(this).parents('.js-slider').find('.js-slider-items').slick('slickPrev');
    });

    $('.js-slider[data-id="product"] .js-slider-next').on('click', function() {
      $(this).parents('.js-slider').find('.js-slider-items').slick('slickNext');
    });


    /**
    ** Reinsurance
    ****************************************/

    $('.js-slider[data-id="reinsurance"] .js-slider-items').slick({
      autoplay: true,
      autoplaySpeed: 2000,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      dotsClass: "v-footer__dots",
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 719,
          settings: {
            slidesToShow: 3
          }
        }
      ]
    });

  }

})();
