/**
** JAVASCRIPT
** Name: Navigation
********************************************************************************/

(function() {
  'use strict';


  if($('.js-navigation').length > 0) {


    /**
    ** Variables
    ****************************************/

    var $win     = $(window),
        $body    = $('body'),
        $header  = $('.js-header'),
        $nav     = $('.js-navigation'),
        $items   = $('.js-navigation-item'),
        $links   = $('.js-navigation-link'),
        $close   = $('.js-navigation-close'),
        $back    = $('.js-navigation-back'),
        $summary = $('.js-summary');

    var scrollTop = 0;

    window.navActive = false;


    /**
    ** Links
    ****************************************/

    $links.on('click', function(event) {
      event.preventDefault();


      // Variables
      var $link   = $(this),
          $item   = $link.parents('.js-navigation-item'),
          hFixed  = $header.hasClass('is-fixed'),
          sFixed  = $summary.length > 0 ? $summary.hasClass('is-fixed') : false;


      // Before
      if($('.js-navigation-item.is-active').length < 1 && window.navActive == false) {
        scrollTop = $win.scrollTop();
        window.navActive = true;

        if(hFixed) {
          $header.addClass('was-fixed');
        }

        if(sFixed) {
          $summary.addClass('was-fixed');
        }
      }


      // Set classes
      $items.not($item).removeClass('is-active');
      $item.toggleClass('is-active');


      // After
      if($('.js-navigation-item.is-active').length > 0) {
        $body.addClass('navigation-panel');
        $body.css('top', (-scrollTop) + "px");
        $nav.animate({
          scrollTop: 0
        }, 300);
      }
      else {
        $body.css('top', '');
        $body.removeClass('navigation-panel');
        $win.scrollTop(parseInt(scrollTop || '0'));
        window.navActive = false;
        $header.removeClass('was-fixed');

        if($summary.length > 0) {
          $summary.removeClass('was-fixed');
        }
      }

    });


    /**
    ** Close
    ****************************************/

    $close.on('click', function(event) {
      $items.removeClass('is-active');
      $body.css('top', '');
      $body.removeClass('navigation-panel');
      $win.scrollTop(parseInt(scrollTop || '0'));
      window.navActive = false;
      $header.removeClass('was-fixed');

      if($summary.length > 0) {
        $summary.removeClass('was-fixed');
      }
    });


    /**
    ** Back
    ****************************************/

    $back.on('click', function(event) {
      event.preventDefault();

      $items.removeClass('is-active');
      $body.removeClass('navigation-panel');
    });

  }

})();
