/**
** JAVASCRIPT
** Name: Bathtub
********************************************************************************/

(function() {
  'use strict';


  if($('.js-bathtub').length > 0) {


    /**
    ** Variables
    ****************************************/

    var $win     = $(window),
        $doc     = $(document),
        $body    = $('body'),
        $bathtub = $('.js-bathtub'),
        $buttons = $('.js-bathtub-button'),
        $items   = $('.js-bathtub-item'),
        $nojs    = $('.js-bathtub-nojs'),
        hash     = window.location.hash,
        id;


    /**
    ** Functions
    ****************************************/

    function setActive(id) {
      var $button  = $('.js-bathtub-button[data-id="' + id + '"]'),
          $targets = $('.js-bathtub-item[data-id="' + id + '"]');

      $items.not($targets).removeClass('is-active');
      $targets.addClass('is-active');

      $buttons.not($button).removeClass('is-active');
      $button.addClass('is-active');
    }


    /**
    ** Load
    ****************************************/

    $win.on('load', function(event) {
      id = hash.substring(1) != "" ? hash.substring(1) : $buttons.first().data('id');

      $nojs.remove();

      setActive(id);
    });


    /**
    ** Click
    ****************************************/

    $buttons.on('click', function(event) {
      event.preventDefault();

      id = $(this).data('id');

      setActive(id);
    });

  }

})();
