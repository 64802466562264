/**
** JAVASCRIPT
** Name: Display
********************************************************************************/

(function() {
  'use strict';


  if($('.js-display').length > 0) {


    /**
    ** Variables
    ****************************************/

    var $win     = $(window),
        $doc     = $(document),
        $body    = $('body'),
        $display = $('.js-display'),
        $buttons = $('.js-display-button'),
        $targets = $('.js-display-target');


    /**
    ** Scroll
    ****************************************/

    $buttons.on('click', function(event) {
      event.preventDefault();

      var $this   = $(this),
          target  = $this.data('target'),
          $target = $('.js-display-target[data-category="' + target + '"]');

      if($this.hasClass('is-active')) {
        $this.removeClass('is-active');
        $targets.removeClass('u-hidden');
      }
      else {
        $buttons.not($this).removeClass('is-active');
        $this.addClass('is-active');

        $targets.not($target).addClass('u-hidden');
        $target.removeClass('u-hidden');
      }
    });

  }

})();
