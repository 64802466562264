/**
** JAVASCRIPT
** Name: Toggle
********************************************************************************/

(function() {
  'use strict';


  if($('.js-toggle-trigger').length > 0) {


    /**
    ** Variables
    ****************************************/

    var $win     = $(window),
        $doc     = $(document),
        $body    = $('body'),
        $trigger = $('.js-toggle-trigger');


    /**
    ** Scroll
    ****************************************/

    $trigger.on('click', function(event) {
      event.preventDefault();

      var $this   = $(this),
          $toggle = $this.parents('.js-toggle'),
          group   = $toggle.data('toggle-group');

      if(group) {
        $('.js-toggle[data-toggle-group="' + group + '"]').not($toggle).removeClass('is-active');
      }

      $toggle.toggleClass('is-active');
    });

  }

})();
